import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

const AccordionItemOld = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItemOld),
);

interface HideProps {
  $hide?: boolean;
}

const hideStyle = css<HideProps>`
  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}
`;

export const MenuStyled = styled.div``;

export const PartnerLogin = styled.div<HideProps>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  p {
    inline-size: 100%;
  }
  ${hideStyle};
`;

export const PartnerLoginLink = styled(Link).attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  width: 100%;
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.fontRegular};
  color: ${colors.solidBlack};
`;

export const SearchWrapper = styled.div`
  padding: ${spacing.small} 0 ${spacing.medium} 0;
`;

export const MenuWrapper = styled.div<HideProps>`
  min-height: 78%;
  ${hideStyle};
`;

const labelStyle = css`
  font-family: ${fontFamilies.fontRegularBold};
  font-size: ${fontSizes.header3Content};
  line-height: ${lineHeights.header3Content};
`;

export const AccordionLabel = styled.span`
  ${labelStyle};
`;

const linkStyle = css`
  display: block;
  text-decoration: none;
  margin-top: 8px;
  min-height: 44px;
  color: ${colors.solidBlack};

  &:hover {
    color: ${colors.solidBlack};
  }
`;

export const MenuLink = styled(Link)`
  ${linkStyle};

  padding-block: ${spacing.regular} ${spacing.small};
  border-top: thin solid ${colors.gray4};

  ${labelStyle};
`;

export const MenuSubLink = styled(Link)<{ subAccordion?: boolean }>`
  ${linkStyle};
  font-family: ${fontFamilies.fontHero};
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  text-transform: uppercase;

  ${({ subAccordion }) =>
    subAccordion &&
    css`
      font-family: ${fontFamilies.fontRegularBold};
      font-size: 1rem;
      line-height: var(--line-height-header-3-content);
      text-transform: capitalize;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
        text-underline-offset: ${spacing.xxsmall};
      }
    `};
`;

export const SubAccordionItem = styled(AccordionItemOld)`
  ${linkStyle};
  font-family: ${fontFamilies.fontHero};
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  text-transform: uppercase;

  div {
    padding-inline-start: 0 !important;
  }
`;
