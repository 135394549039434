import dynamic from 'next/dynamic';
import { useToggle } from 'react-use';

import {
  constructSubMenuUrl,
  createMenuItemUrl,
} from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import {
  MenuSubTreeFragment,
  MenuTreeFragment,
} from '@hultafors/solidgear/types';

import PartnerSvg from '../../svg/partner-portal.svg';
import LogoSvg from '../../svg/solid-gear-logo-text.svg';
import { Paragraph } from '../paragraph/paragraph';
import { SearchBox } from '../SearchBox/SearchBox';

import {
  AccordionLabel,
  MenuLink,
  MenuSubLink,
  MenuWrapper,
  PartnerLogin,
  PartnerLoginLink,
  SearchWrapper,
  SubAccordionItem,
} from './menu.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionOld),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItemOld),
);
const SlideOut = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOut),
);
const SlideOutHeader = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
);

interface MenuProps {
  toggleMenu(...props: any[]): void;
  isOpen: boolean;
  menuItems: MenuTreeFragment[];
  partnerPortalUrl?: string | null;
  partnerLoginLabel?: string | null;
  categoriesLabel?: string | null;
  chooseLanguageLabel?: string | null;
  closeLabel?: string | null;
}

export const Menu: React.FC<MenuProps> = ({
  toggleMenu,
  isOpen,
  menuItems,
  partnerPortalUrl,
  partnerLoginLabel,
  closeLabel,
}) => {
  const { global, settings } = useGlobal();
  const [hasSearchResults, toggleHasSearchResults] = useToggle(false);

  function subMenuMapper(item: MenuSubTreeFragment) {
    if (item.hide) {
      return null;
    }
    const key = `SubMenu-${item.id}`;
    let url = null;

    /*
    item.url always comes in as
    an empty string if left empty in dato
    */
    if (item.url && item.url.trim() !== '') {
      url = item.url;
    }

    if (item?.children?.length) {
      return (
        <Accordion allowZeroExpanded key={key}>
          <SubAccordionItem label={item.title} plus>
            {item.children?.map((subItem, index: number) => {
              if (subItem.hide) {
                return null;
              }
              const subKey = `SecondSubMenu-${subItem.id}-${index}`;
              let subUrl = null;

              if (subItem.url && subItem.url.trim() !== '') {
                subUrl = subItem.url;
              }

              return (
                <MenuSubLink
                  key={subKey}
                  href={subUrl ?? constructSubMenuUrl(subItem) ?? ''}
                  subAccordion
                >
                  {subItem.title}
                </MenuSubLink>
              );
            })}
          </SubAccordionItem>
        </Accordion>
      );
    }

    return (
      <MenuSubLink key={key} href={url ?? constructSubMenuUrl(item) ?? ''}>
        {item.title}
      </MenuSubLink>
    );
  }

  function menuMapper(item: MenuTreeFragment, i: number) {
    const key = `Menu-${item.id}-${i}`;
    if (!!item.children?.length && !item.hide) {
      const label = <AccordionLabel>{item.title}</AccordionLabel>;
      return (
        <AccordionItem key={key} label={label} plus>
          {item.children?.filter(Boolean).map(subMenuMapper)}
        </AccordionItem>
      );
    }
    if (item?.title && !item.hide) {
      const href =
        createMenuItemUrl({
          ...item,
          settings,
        }) || '';

      return (
        <MenuLink key={key} href={href}>
          {item.title}
        </MenuLink>
      );
    }
    return null;
  }

  const closeMenu = () => toggleMenu(false);
  const header = (
    <SlideOutHeader
      onClick={closeMenu}
      closeLabel={closeLabel || ''}
      position="inline-start"
    >
      <LogoSvg height={24} focusable={false} aria-hidden={true} />
    </SlideOutHeader>
  );

  return (
    <SlideOut
      isOpen={isOpen}
      toggle={toggleMenu}
      header={header}
      position="inline-start"
    >
      <SearchWrapper>
        <SearchBox
          className="SearchWrapper"
          isOpen={isOpen}
          toggleResults={toggleHasSearchResults}
        />
      </SearchWrapper>
      {!hasSearchResults && (
        <MenuWrapper>
          {menuItems.length > 0 && (
            <Accordion allowZeroExpanded>{menuItems.map(menuMapper)}</Accordion>
          )}
        </MenuWrapper>
      )}
      {partnerPortalUrl && !hasSearchResults && (
        <PartnerLogin>
          <Paragraph>
            <PartnerLoginLink href={partnerPortalUrl || ''}>
              {partnerLoginLabel}
              <PartnerSvg
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            </PartnerLoginLink>
          </Paragraph>
        </PartnerLogin>
      )}
    </SlideOut>
  );
};
