import { useState } from 'react';

import dynamic from 'next/dynamic';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { createMenuItemUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';

import GlobeSvg from '../../svg/globe.svg';
import HamburgerSvg from '../../svg/hamburger.svg';
import PartnerSvg from '../../svg/partner-portal.svg';
import SearchSvg from '../../svg/search.svg';
import LogoSvg from '../../svg/solid-gear-logo-text.svg';
import { RouterLink, RouterLinkStyled } from '../RouterLink/RouterLink';

import {
  End,
  FavoriteToggle,
  LogoLink,
  MenuToggler,
  Middle,
  NavStyled,
  NavToggle,
  NavToggleLabel,
  PartnerPortalLink,
  Start,
} from './nav.styled';

const DesktopMenu = dynamic(() =>
  import('../DeskTopMenu/DesktopMenu').then((mod) => mod.DesktopMenu),
);
const Menu = dynamic(() => import('../menu/menu').then((mod) => mod.Menu));
const Favorites = dynamic(() =>
  import('../favorites/favorites').then((mod) => mod.Favorites),
);

interface MenuSettings {
  desktopMenu: boolean;
  desktopType: string;
  mobileMenu: boolean;
  favorites: boolean;
  cart: boolean;
}

export const Nav = () => {
  const defaultOpenSettings: MenuSettings = {
    cart: false,
    desktopMenu: false,
    desktopType: '',
    favorites: false,
    mobileMenu: false,
  };
  const { global, allMenuTrees: siteMenu, settings } = useGlobal();
  const [isOpen, setIsOpen] = useState(defaultOpenSettings);
  const { toggle, activeMarket } = useMarketPicker();

  // TODO make hook for toggle menus maybe?
  const toggleMenu = () => {
    const open = !isOpen.mobileMenu;
    setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
  };

  const toggleDesktopMenu = (type: string) => {
    let open = true;
    if (isOpen.desktopType === type || !type || type === 'main') {
      open = false;
      type = '';
    }
    setIsOpen({ ...defaultOpenSettings, desktopMenu: open, desktopType: type });
  };

  const toggleFavoritesMenu = (force?: boolean) => {
    let open = !isOpen.favorites;
    if (typeof force !== 'undefined') {
      open = force;
    }
    setIsOpen({ ...defaultOpenSettings, favorites: open });
  };

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return (
        <GlobeSvg width={24} height={24} aria-hidden={true} focusable={false} />
      );
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }

  return (
    <>
      <NavStyled>
        <Start>
          <MenuToggler
            onClick={toggleMenu}
            aria-label={global?.showMenu || 'Menu'}
          >
            <HamburgerSvg
              width={24}
              height={24}
              aria-hidden={true}
              focusable={false}
            />
          </MenuToggler>
          <LogoLink
            href="/"
            onClick={() => setIsOpen({ ...defaultOpenSettings })}
            aria-label="Solid Gear"
          >
            <LogoSvg
              width={157}
              height={24}
              aria-hidden={true}
              focusable={false}
            />
          </LogoLink>
        </Start>
        <Middle>
          {siteMenu?.length > 0 &&
            siteMenu.map((item, i) => {
              if (
                !!item.children?.length &&
                !item.hide &&
                !item.pageLink &&
                !item.url
              ) {
                return (
                  <RouterLinkStyled
                    as="button"
                    key={`MenuItem-${item.id}`}
                    onClick={() => toggleDesktopMenu(item.title || '')}
                  >
                    <span className="link">{item.title}</span>
                  </RouterLinkStyled>
                );
              } else if (
                (!item.hide && item.pageLink) ||
                (!item.hide && item.url)
              ) {
                return (
                  <RouterLink
                    onClick={() => toggleDesktopMenu('main')}
                    key={`MenuItemLink-${i}`}
                    href={
                      createMenuItemUrl({
                        settings,
                        url: item.url || '',
                      }) || ''
                    }
                    dataValue={undefined}
                    id={undefined}
                  >
                    {item.title}
                  </RouterLink>
                );
              }
              return null;
            })}
        </Middle>
        <End>
          <NavToggle onClick={() => toggle(true)}>
            {renderLanguageIcon()}
            <NavToggleLabel>{global?.changeLanguage ?? ''}</NavToggleLabel>
          </NavToggle>

          <NavToggle onClick={() => toggleDesktopMenu('search')} $desktop>
            <SearchSvg
              width={24}
              height={24}
              aria-hidden={true}
              focusable={false}
            />
            <NavToggleLabel>{global?.searchLabel ?? ''}</NavToggleLabel>
          </NavToggle>
          <FavoriteToggle
            isFavorite
            label={global?.favoritesHeader ?? ''}
            toggle={toggleFavoritesMenu}
          />
          {global?.partnerPortalUrl && (
            <PartnerPortalLink
              href={global.partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
              $desktop
            >
              <PartnerSvg
                width={24}
                height={24}
                aria-hidden={true}
                focusable={false}
              />
              <NavToggleLabel>
                {global?.partnerPortalLabel ?? ''}
              </NavToggleLabel>
            </PartnerPortalLink>
          )}
        </End>
      </NavStyled>
      <Menu
        isOpen={!!isOpen.mobileMenu}
        toggleMenu={toggleMenu}
        menuItems={siteMenu}
        partnerPortalUrl={global?.partnerPortalUrl}
        partnerLoginLabel={global?.partnerPortalLabel}
        chooseLanguageLabel={global?.changeLanguage}
        closeLabel={global?.closeMenu}
      />
      <Favorites
        isOpen={!!isOpen.favorites || false}
        toggleMenu={toggleFavoritesMenu}
        header={global.favoritesHeader}
        clearText={global.clear}
        rrpLabel={global.rrpLabel}
        rrpIncludingVatLabel={global.rrpIncludingVatLabel}
        isSmall
      />
      {isOpen.desktopMenu && (
        <DesktopMenu
          isOpen={isOpen.desktopMenu}
          openType={isOpen.desktopType}
          menuItems={siteMenu}
          toggleDesktopMenu={toggleDesktopMenu}
        />
      )}
    </>
  );
};
